<template>
  <loading v-if="initialLoad" />
  <no-results
    v-else-if="$_.isEmpty(orderedChanges)"
    title="No results"
    message="There are no changes."
    style="margin: auto;"
  />
  <div v-else class="site-changes">
    <div v-for="(change, index) in mappedChanges" :key="index">
      <p class="has-margin-bottom-25">
        <strong class="is-size-7"
          >{{ $moment(change[0].timestamp).format("LLLL") }}:</strong
        >
      </p>
      <site-change-item
        :changes="change"
        :show-view-state="true"
        class="has-margin-bottom-100"
      />
      <span />
    </div>
    <load-more v-if="!complete" :loading="loading" @loadMore="getData" />
  </div>
</template>

<script>
import { mapChanges } from "@src/services/siteChange";
import { collection, orderBy, query } from "@firebase/firestore";

export default {
  name: "SiteChangesView",
  components: {
    "site-change-item": () => import("@shared/sites/_siteChangeItem.vue")
  },
  props: {
    siteId: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      initialLoad: true,
      loading: true,
      complete: false,
      limit: 5,
      changes: {}
    };
  },
  computed: {
    mappedChanges() {
      return mapChanges(this.orderedChanges);
    },
    orderedChanges() {
      return this.$_.orderBy(this.changes, ["timestamp"], ["desc"]);
    },
    oldestChange() {
      const change = this.$_.last(this.orderedChanges);
      return this.$_.get(change, "timestamp");
    }
  },
  created() {
    this.getData().then(() => (this.initialLoad = false));
  },
  methods: {
    async getData() {
      this.loading = true;
      const changesRef = query(
        collection(this.$firestore, `sites/${this.siteId}/changes`),
        orderBy("timestamp", "desc")
      );

      return this.$store
        .dispatch(
          "pagination/getPaginated",
          {
            ref: changesRef,
            limit: this.limit,
            cursor: this.oldestChange
          },
          { root: true }
        )
        .then(({ results, complete }) => {
          this.loading = false;
          this.complete = complete;
          results.forEach(change => {
            this.$set(
              this.changes,
              change.id,
              this.$_.merge({}, change.data(), {
                _id: change.id,
                timestamp: change.data().timestamp.toDate()
              })
            );
          });
        })
        .catch(() => {
          this.loading = false;
          this.$toast.open({
            message: `Error getting changes`,
            type: "is-danger"
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";

.site-changes {
  /deep/.change:not(:last-child) {
    border-bottom: 1px solid $border;
    padding-bottom: 1rem;
  }
}
</style>
